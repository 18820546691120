.pdf-page {
    width: 8.5in;
    height: 11in;
    margin: 0 auto;
    padding: 1in;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .back-button {
    align-self: flex-start;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  
  .markdown-content {
    flex: 1;
    overflow-y: auto;
    font-family: 'Arial', sans-serif;
    line-height: 1.5;
  }
  
  .markdown-content h1, 
  .markdown-content h2, 
  .markdown-content h3, 
  .markdown-content h4, 
  .markdown-content h5, 
  .markdown-content h6 {
    margin-top: 1.2em;
  }
  
  .markdown-content p {
    margin: 0.8em 0;
  }
  