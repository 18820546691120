@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.vision {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #111;
  padding: 0;
  overflow: hidden;
}

.overlayv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #50BFE6;
  opacity: 0.7;
}

.textv {
  position: relative;
  z-index: 10;
  text-align: center;
  max-width: 800px;
  padding: 20px;
}

.textv h2 {
  font-size: 4em;
  font-weight: 800;
  color: #50BFE6;
  line-height: 1em;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.textv p {
  font-size: 1.1em;
  color: #fff;
  margin: 20px 0;
  font-weight: 400;
  max-width: 700px;
}

.textv a {
  display: inline-block;
  font-size: 1em;
  background: #fff;
  padding: 10px 30px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  margin-top: 10px;
  color: #111;
  letter-spacing: 2px;
  transition: 0.2s;
}

.textv a:hover {
  letter-spacing: 6px;
}

@media (max-width: 991px) {
  .vision,
  .vision header {
    padding: 40px;
  }
  .textv h2 {
    font-size: 3em;
  }
  .textv h3 {
    font-size: 2em;
  }
}
