.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #50BFE6; /* Light blue background */
  }
  
  .signup-form {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 500px; /* Adjust the width as needed */
  }
  
  .signup-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .mb-3 {
    margin-bottom: 15px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    box-sizing: border-box;
  }
  
  .btn {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .forgot-password {
    text-align: center;
    margin-top: 10px;
  }
  
  .forgot-password a {
    color: #007bff;
    text-decoration: none;
  }
  
  .forgot-password a:hover {
    text-decoration: underline;
  }
  