.table-container {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  
  .styled-table {
    width: 80%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 1em;
    font-family: 'Arial, sans-serif';
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  
  .styled-table thead tr {
    background-color: black;
    color: #ffffff;
    text-align: left;
  }
  
  .styled-table th, .styled-table td {
    padding: 12px 15px;
  }
  
  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #87CEEB;
  }
  
  .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #fff;
  }