.dashboard-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    margin: 20px;
}

.section-title {
    font-size: 1.5em;
    margin: 20px 0;
    color: #333;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 300px;
}

.card {
    background-color: #87CEEB; /* mediumBlue */
    border-radius: 10px;
    padding: 15px;
    width: 175px;
    height:125px; /* Fixed height for uniformity */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    overflow: hidden; /* Prevent overflow */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-header {
    font-weight: bold;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-subheader {
    font-size: 0.9em;
    color: #50BFE6; /* secondaryBlue */
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.view-all-button {
    background-color: #50BFE6; /* secondaryBlue */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    margin-top: 20px;
}

.view-all-button:hover {
    background-color: #339BBF;
}