
.chatbot-card {
  /*width: 50px; */
 /*max-width: 25rem; */
  margin: 10px !important;
  /*height: 25rem; */
  border: 1px solid #dee2e6 !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  background-color: #a3d9f5 !important; /* Light blue background */
  width: '18rem' !important;
  margin: '10px' !important; 
  height: '250px' !important; 
}

.card-text {
  font-size: 1rem;
  text-align: center;
  margin: 0;
  padding: 0;
}

@media (max-width: 576px) {
  .chatbot-card {
   display: none !important;
  }
}
